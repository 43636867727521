<template>
    <div>
        <b-modal id="popup-recuperar-senha"
                 ref="popup-recuperar-senha"
                 centered size="md"
                 hide-footer hide-header>

            <form>

                <b-form-group class="font-weight-bold py-2" label="Digite seu e-mail *" label-for="input-email">
                    <b-form-input type="text" id="input-email" v-model="email" required></b-form-input>
                </b-form-group>

            </form>

            <div class="w-100 py-2 text-center">
                <button class="btn-sm btn-secondary mr-1" @click="fecharPopup">CANCELAR</button>
                <button class="btn-sm btn-primary ml-1" @click="verificarEmail">RECUPERAR SENHA</button>
            </div>

            <div v-if="verificacaoMensagem" class="text-center mt-3">
                <p :class="verificacaoSucesso ? 'text-success' : 'text-danger'">{{verificacaoMensagem}}</p>
            </div>

        </b-modal>
    </div>
</template>

<script>
    import axios from 'axios'
    import { mapActions } from 'vuex'

    export default {
        name: 'PopupRecuperarSenha',
        data() {
            return {
                email: '',
                verificacaoSucesso: false,
                verificacaoMensagem: ''
            }
        },
        methods: {
            ...mapActions('authentication', ['recuperarSenha']),
            verificarEmail() {
                axios.post('/api/autenticacao/senha/recuperar', { "email": this.email })
                    .then(res => {
                        this.verificacaoMensagem = res.data.Mensagem;

                        this.verificacaoSucesso = res.data.Sucesso;
                        this.limparEmail();
                })
                .catch(err => {
                    console.log(err);
                })
            },
            mostrarPopup() {
                this.limparEmail();
                this.$refs['popup-recuperar-senha'].show();
            },
            fecharPopup() {
                this.limparEmail();
                this.$nextTick(() => {
                    this.$bvModal.hide('popup-recuperar-senha')
                })
            },
            limparEmail() {
                this.email = '';
            },
        },
    }
</script>

<style scoped>
    .erro-email {
        color: red;
        font-size: 12px;
    }
</style>