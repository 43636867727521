<template>

    <div class="container-fluid login-page">

        <div class="row">

            <div class="login-bg hidden-sm-down p-0 col-lg-9 col-md-6">
                <div class="caixa-imagem-principal">
                    <img :src="$hostOmni + '/images/Logos/fundo_inicial.jpg'" id="img-fundo">
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 login-panel">
                <div>
                    <div>
                        <img 
                             id="img-logo"
                             :src="imagemLogoEmpresa"
                             @error="alterarImagem"
                        >
                    </div>

                    <div v-if="mensagemLoginInvalido" id="login-error">
                        {{mensagemLoginInvalido}}
                    </div>

                    <b-form class="form-login" @submit.prevent="fazerLogin">

                        <div>
                            <b-form-group>
                                <b-form-input type="text" placeholder="Usuário" v-model="nome" required></b-form-input>
                            </b-form-group>
                        </div>

                        <div>
                            <b-form-group>
                                <b-form-input type="password" v-model="senha" placeholder="Senha" required></b-form-input>
                            </b-form-group>
                        </div>

                        <div>

                        </div>
                        <div class="row">
                            <div class="col" style="text-align:left">
                                <b-form-checkbox id="checkbox-1" name="checkbox-lembrar" v-model="memorizarLogin">
                                    Lembrar
                                </b-form-checkbox>
                            </div>
                            <div class="col-6 text-right">
                                <button type="button" class="btn botao-recuperar-senha" @click="recuperarSenha">Esqueci a senha</button>
                            </div>
                        </div>

                        <b-button variant="light" type="submit" id="btn-acessar">ACESSAR</b-button>
                        
                    </b-form>


                    <div id="text-versao">
                        <p> SD-Omni </p>
                    </div>
                </div>
            </div>
        </div>

        <recuperar-senha-popup ref="popupRecuperarSenha"/>
        <validar-senha-popup ref="popupValidarSenha" />
        <b-overlay :show="loading" rounded="sm" no-wrap>
        </b-overlay>

    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import RecuperarSenhaPopup from '@/components/popups/RecuperarSenhaPopup.vue'
    import ValidarSenhaPopup from '@/components/popups/ValidarSenhaPopup.vue'
    import axios from "axios";

    export default {
        name: 'LoginPage',
        components: {
            RecuperarSenhaPopup,
            ValidarSenhaPopup
        },
        data: function () {
            return {
                nome: '',
                senha: '',
                memorizarLogin: false,
                loading: false,
                imagemLogoEmpresa: '',
                tentativasCarregarImagem : 0
            };
        },
        methods: {
            ...mapActions('authentication', ['login']),
            fazerLogin: function () {
                this.loading = true;
                
                axios.get(`api/autenticacao/${this.nome}/login-restrito`).then(response => {
                    if (!!response.data) {
						this.$bvModal.msgBoxConfirm([this.$createElement("div", {
							domProps: {
								innerHTML: `Este usuário já está conectado em outro dispositivo.<br /><br />
                                    A SD-Omni não permite 2 ou mais conexões simultâneas a um mesmo usuário.
                                    A conexão será encerrada no outro dispositivo.<br /><br />
                                    Deseja continuar?`
                            }
                        })], {
							title: "Atenção",
							size: "md",
							buttonSize: "sm",
							okVariant: "danger",
							okTitle: "Continuar",
							cancelTitle: "Cancelar"
                        }).then(continuar => {
                            if (continuar) {
                                this.autenticar();
                                return;
                            }
                            this.loading = false;
                        })
                        return;
                    }
                    this.autenticar();
                }).catch(() => {
                    this.loading = false;
                });
            },
            autenticar() {
				let credenciais = {
					usuario: this.nome,
					senha: this.senha,
					lembrar: this.memorizarLogin,
                    Fingerprint: ''
				}
				this.login(credenciais).then(() => {
					this.$router.push({ path: '/' })
				})
					.catch(err => console.error(err))
					.finally(() => this.loading = false)
            },
            verificaAutenticacao() {
                if (this.usuarioAutenticado) {
                    this.$router.push('/');
                }
            },
            recuperarSenha() {
                this.$refs.popupRecuperarSenha.mostrarPopup();
            },
            validarSenhaNova() {
                let urlPath = this.$route.path;
                const URL_VALIDACAO = 'recuperar-senha';

                if (urlPath.includes(URL_VALIDACAO)) {
                    this.$refs.popupValidarSenha.mostrarPopup();
                }
            },
            alterarImagem() {
                const diretorioImagem = this.$hostOmni + "/images/Logos/logo-";
                if (this.tentativasCarregarImagem < 3) {
                    const listaExtensoesImagem = [".svg", ".png", ".jpg", ".jpeg", ".webp"];
                    let nomeEmpresa = document.location.host.split(".")[0]
                    this.imagemLogoEmpresa = diretorioImagem + nomeEmpresa + listaExtensoesImagem[this.tentativasCarregarImagem];
                    this.tentativasCarregarImagem += 1;
                    return;
                }
                this.imagemLogoEmpresa = diretorioImagem + "sharkdata.png";
            }
        },
        computed: {
            ...mapState('authentication', { usuarioAutenticado: 'usuario', mensagemLoginInvalido: 'mensagemErro' }),
        },
        mounted() {
            this.verificaAutenticacao();
            this.validarSenhaNova();
            this.alterarImagem();
        }
    }

</script>

<style scoped>
	.login-panel {
		overflow: hidden;
		background-color: #123461;
		padding: 30px 45px;
		font-size: 14px;
		color: #FFF;
		text-align: center;
		height: auto;
	}

    .caixa-imagem-principal {
        height: 100vh;
    }

    #img-fundo {
        width: 100%;
        background: url(/Template/Imagens/fundo_inicial.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 100%;
        float: left;
    }

    .caixa-imagem-cliente {
        width: 100%;
        max-height: 150px;
    }

    #img-logo {
        width: auto;
        height: 100%;
        min-height: 50px;
        max-height: 110px;
        background-color: #FFF;
        margin: 20% 0;
    }

    .form-login input {
        border-radius: 0;
        color: silver;
        font-weight: 500;
        letter-spacing: 0.5px;
    }

    #btn-acessar {
        width: 100%;
        margin-top: 10%;
        padding: 6px 16px;
        color: rgba(0, 0, 0, 0.87);
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
        border-radius: 0;
    }

    #text-versao {
        font-size: x-small;
        position: absolute;
        bottom: 5%;
        right: 0;
        left: 0;
    }

    #login-error {
        background-color: rgba(170, 31, 36, 0.65);
        padding: 5px;
        margin-bottom: 10px;
        width: 100%;
    }

    .botao-recuperar-senha {
        color: white;
        padding: 0;
        font-size: 14px;
    }
</style>